<template>
  <roboto class="size-18px">{{ currentPageFirstDate.getFullYear() }}</roboto>
  <sub-label>年</sub-label>
  <roboto class="size-18px">{{ currentPageFirstDate.getMonth() + 1 }}</roboto>
  <sub-label>月</sub-label>
</template>

<script lang="ts">
import { useCalendarStore } from '@/PC/store/calendarStore'
import { format } from 'date-fns'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'YearAndMonthLabel',
  computed: {
    ...mapState(useCalendarStore, ['currentPageFirstDate']),
    formattedDate() {
      // FIXME: なぜかunknown castしないと通らない
      return format(this.currentPageFirstDate as unknown as Date, 'yyyy年 MM月')
    }
  },
  methods: {}
})
</script>

<style scoped>
/* CSS Here */
</style>
