<template>
  <div class="spacer"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Spacer'
})
</script>

<style scoped>
/* NOTE: cssクラス当てるだけなので、utlity.cssの方を使うようにする */
.spacer {
  flex: 1;
  flex-grow: 1;
}
</style>
