<template>
  <p class="small-text"><slot /></p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SmallText'
})
</script>

<style scoped>
/* <small-text> */
.small-text {
  font-size: var(--font-size-12px);
  font-weight: var(--font-weight-medium);
}
.small-text.bold {
  font-weight: var(--font-weight-bold);
}
.small-text.gray-1 {
  color: var(--font-gray-1);
}
.small-text.regular {
  font-weight: var(--font-weight-regular);
}
</style>
