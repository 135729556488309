<script setup lang="ts">
import { useUserStore } from '@/PC/store/userStore';
import { ref } from 'vue';
import AccountMenu from './AccountMenu.vue';
import NitUserIcon from './NitUserIcon.vue';

const showMenu = ref(false)
const userStore = useUserStore()
</script>

<template>
  <div>
    <nit-user-icon :src="userStore.userIcon" @click="showMenu = !showMenu" />
    <account-menu v-if="showMenu" />
  </div>
</template>

<style scoped>
/* css */
</style>