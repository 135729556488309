<template>
  <div class="controller lo-h-center">
    <button class="controller-button lo-center" @click="toggleMonthlyCalendar">
      <img class="calender-button-img" src="@/assets/calendar.svg" />
    </button>
    <button
      v-keydown:ArrowLeft="moveToPreviousPage"
      class="controller-button lo-center"
      @click="moveToPreviousPage"
    >
      <img class="flip" src="@/assets/arrow.svg" />
    </button>
    <button
      v-keydown:ArrowRight="moveToNextPage"
      class="controller-button lo-center"
      @click="moveToNextPage"
    >
      <img src="@/assets/arrow.svg" />
    </button>
    <div v-show="showMonthlyCalendar">
      <monthly-calendar />
    </div>
  </div>
  <div v-show="showMonthlyCalendar">
    <div @click="toggleMonthlyCalendar" class="overlay bg-white fill-vw-vh pointer"></div>
  </div>
</template>

<script lang="ts">
import { keydown } from '@/common/directives/v-keydown'
import { useCalendarStore } from '@/PC/store/calendarStore'
import { defineComponent } from 'vue'
import MonthlyCalendar from './MonthlyCalendar.vue'

export default defineComponent({
  name: 'componentName',
  directives: {
    ...keydown
  },
  components: {
    MonthlyCalendar
  },
  methods: {
    toggleMonthlyCalendar() {
      useCalendarStore().toggleMonthlyCalendar()
    },
    moveToNextPage() {
      useCalendarStore().moveToNextPage()
    },
    moveToPreviousPage() {
      useCalendarStore().moveToPreviousPage()
    }
  },
  computed: {
    showMonthlyCalendar() {
      return useCalendarStore().showMonthlyCalendar
    }
  },
})
</script>

<style scoped>
.controller {
  border: 1px solid var(--border-black);
  height: 36px;
  position: relative;
}
.monthly-calendar {
  position: absolute;
  top: 48px;
  right: 0;
  z-index: var(--z-index-90);
}
.overlay {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: var(--z-index-60);
}
.controller-button {
  width: 40px;
  height: 40px;
}
.controller {
  border-radius: var(--border-radius-6px);
}
.calender-button-img {
  margin-bottom: 1px;
}

@media screen and (max-width: 800px) {
  .controller {
    padding: 0;
  }
}
</style>
