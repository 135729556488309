<template>
  <p class="sup-description"><slot /></p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SupDescription'
})
</script>

<style scoped>
.bold {
  font-weight: var(--font-weight-bold);
}
.sup-description {
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-regular);
}
.gray-1 {
  color: var(--font-gray-1);
}
.gray-3 {
  color: var(--font-gray-3);
}
</style>
