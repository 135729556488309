import { formatDateWithDayName, formatHourMin } from "@WombatTechnology/nitte-time-lib"

export class DateTime {
  constructor(
    readonly id: string,
    readonly date: Date | null,
    // ソートのこと考えるとここはDateで持っておくのが良さそう
    public startTime: Date | null,
    public endTime: Date | null
  ) { }

  get format(): string {
    if (this.isDateOnly) {
      return formatDateWithDayName(this.date!)
    }
    if (this.isDateAndStart) {
      return `${formatDateWithDayName(this.date!)} ${formatHourMin(this.startTime!)} 〜`
    }
    return `${formatDateWithDayName(this.date!)} ${formatHourMin(this.startTime!)} 〜 ${formatHourMin(this.endTime!)}`
  }

  private hasDate(): boolean { return !!this.date }
  private hasStartTime(): boolean { return !!this.startTime }
  private hasEndTime(): boolean { return !!this.endTime }

  private get isDateOnly(): boolean {
    return this.hasDate() && !this.hasStartTime() && !this.hasEndTime()
  }

  private get isDateAndStart(): boolean {
    return this.hasDate() && this.hasStartTime() && !this.hasEndTime()
  }

  private get isFullFilled(): boolean {
    return this.hasDate() && this.hasStartTime() && this.hasEndTime()
  }
}
