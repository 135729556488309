<script setup lang="ts">
import { auth } from "@/common/helpers/firebase"
const logout = async () => {
  await auth.signOut()
}
</script>

<template>
  <section class="navigation-menu bg-sub-1 shadow-high bd-3px lo-v">
    <a
      href="https://site.nitte.app"
      target="_blank"
      class="selectable-item pd-rect"
    >
      <label class="regular">日程調整のNitteへ</label>
    </a>

    <button class="selectable-item pd-rect lo-v-start bd-3px" @click="logout">
      <label class="regular">ログアウト</label>
    </button>
  </section>
</template>

<style scoped>
.navigation-menu {
  position: absolute;
  /* 調整値 */
  top: 56px;
  right: var(--base-16px);
  z-index: var(--z-index-70);
}
.navigation-menu-sp {
  width: 280px;
  position: absolute;
  top: var(--mg-52px);
  right: var(--mg-20px);
  z-index: var(--z-index-90);
}
</style>
