<template>
  <strong-g><hr /></strong-g>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'StrongHr'
})
</script>
