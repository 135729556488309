<script setup lang="ts">
import '@/common/extensions/number'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import DayLabel from "./DayLabel.vue"
import { eachDayOfInterval } from 'date-fns'
import { useCalendarStore } from '@/PC/store/calendarStore'

const calendarStore = useCalendarStore()
const { pageWidth, currentPagePeriod } = storeToRefs(calendarStore)

const dayLabelsWidth = computed(() => {
  const timeLineWidth = 50
  const beard = 6
  return pageWidth.value + timeLineWidth + beard
})
</script>


<template>
  <section class="day-labels-container lo-h-flex-end" :style="{ width: dayLabelsWidth.toPx() }">
    <div class="lo-v">
      <div class="day-labels lo-h-center no-select">
        <day-label
          v-for="date in eachDayOfInterval(currentPagePeriod)"
          :key="date.toISOString()"
          :date="date"
        />
      </div>
    </div>
  </section>
</template>

<style scoped>
.day-labels {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--border-black);
}
.day-labels-container {
  padding-left: var(--hour-label-width);
}
.day-label {
  height: 70px;
  border-left: 1px solid var(--border-black);
}
.border-right {
  border-right: 1px solid var(--border-black);
}
.daynum-wrapper {
  width: 34px;
  height: 34px;
}
.today-day-num-circle {
  border-radius: 32px;
  background-color: var(--bg-emphasis-1);
}
.today-dayname {
}
.today-daynum {
  color: var(--main) !important;
}

.vertical-line:last-of-type {
  border-right: 1px solid var(--border-black);
}

/* Responsive */
@media screen and (max-width: 800px) {
  .day-label-dayname {
    font-size: var(--font-size-14px);
  }
  .day-label-daynum {
    font-size: var(--font-size-16px);
  }
}
</style>