import { Period, PeriodWithId } from "@WombatTechnology/nitte-time-lib";
import { v4 as uuidv4 } from 'uuid';

import {
  addMinutes,
  endOfDay,
  isAfter,
  isBefore,
  roundToNearestMinutes,
  set,
  subMinutes,
} from "date-fns";
import { remove, sortBy } from "lodash";
import { defineStore } from "pinia";
import { useCalendarStore } from "./calendarStore";

export type Editing = {
  // 編集しているPeriodのIndex
  index: number
  // 編集前のPeriod
  originalPeriod: PeriodWithId
  editTime: "start" | "end"
}

export type State = {
  editing: Editing | null
  // NOTE: indexで識別しようとすると、currentPageでフィルターした時にズレるので
  // PeriodWithIdを使う
  periods: PeriodWithId[]
}

// 選択中のPeriodの管理を行うStore
export const usePeriodsStore = defineStore("periodsStore", {
  state: (): State => {
    return {
      editing: null,
      periods: [],
    }
  },
  actions: {
    setup() {
      this.$subscribe((mutation, state) => {
        console.log(`#pinia ${mutation.storeId} state changed`, state)
      })
    },
    // Periodを作成する
    createPeriod() {
      // 現在のカーソルの座標からPeriodを作成して追加
      const { currentCusrorDate } = useCalendarStore()
      const start = roundToNearestMinutes(currentCusrorDate, { nearestTo: 15 })
      const period = new PeriodWithId(start, addMinutes(start, 60), uuidv4())
      this.periods.push(period)
      this.startEditing(period, "end")
    },
    /**
     * Periodを編集モードにする
     * @param targetPeriod
     */
    startEditing(targetPeriod: PeriodWithId, editTime: "start" | "end") {
      const index = this.periods.findIndex((p) => p.isTheSame(targetPeriod))
      this.editing = {
        index,
        originalPeriod: targetPeriod,
        editTime,
      }
    },
    endEditing() {
      this.editing = null
    },
    updatePeriodStart() {
      if (!this.isEditing) {
        return
      }
      const { index, originalPeriod } = this.editing!

      const maxStart = subMinutes(originalPeriod.end, 15)
      const start = isBefore(this.cursorTime, maxStart)
        ? this.cursorTime
        : maxStart
      this.periods[index] = new PeriodWithId(
        start,
        originalPeriod.end,
        originalPeriod.id
      )
    },
    updatePeriodEnd() {
      if (!this.isEditing) {
        return
      }
      const { index, originalPeriod } = this.editing!

      const minimumEnd = addMinutes(originalPeriod.start, 15)
      const end = isAfter(this.cursorTime, minimumEnd)
        ? this.cursorTime
        : minimumEnd
      this.periods[index] = new PeriodWithId(
        originalPeriod.start,
        end,
        originalPeriod.id
      )
    },
    remove(period: Period) {
      console.log("delete", period)
      console.log(
        "find",
        this.periods.find((p) => p.isTheSame(p))
      )
      remove(this.periods, (p: Period) => p.isTheSame(period))
    },
    removeAll() {
      this.periods = []
    },
    isAllDay(period: Period) {
      return period.end.getTime() === endOfDay(period.end).getTime()
    },
  },
  getters: {
    isEditing(): boolean {
      return !!this.editing
    },
    periodsSorted(): PeriodWithId[] {
      return sortBy(this.periods, (p) => p.start, "asc")
    },
    cursorTime(): Date {
      const { originalPeriod } = this.editing!
      const { currentCusrorDate } = useCalendarStore()

      // 日はずらさずにカーソルの時間のみを取得する
      const hours = currentCusrorDate.getHours()
      const minutes = currentCusrorDate.getMinutes()
      return set(originalPeriod.start, { hours, minutes })
    },
    timelinePeriods(): PeriodWithId[] {
      const calendarStore = useCalendarStore()
      return this.periods
        .filter((p) => p.end.getTime() !== endOfDay(p.end).getTime())
        .filter((p) => p.isSubsetOf(calendarStore.currentPagePeriod))
    },
    dayPeriods(): Period[] {
      return this.periods.filter(
        (p) => p.end.getTime() === endOfDay(p.end).getTime()
      )
    },
    dayPeriodsDates(): Date[] {
      return this.dayPeriods.map((p) => p.start)
    },
  },
})
