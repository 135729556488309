import { createPinia } from 'pinia'
import { PiniaFirestoreSync } from 'pinia-plugin-firestore-sync'
import { createApp } from 'vue'
import App from './App.vue'
import {
  Description,
  MainTitle,
  MediumG,
  MediumHr,
  Roboto,
  SmallText, Spacer, StrongG,
  StrongHr,
  SubTitle,
  SupDescription,
  SupLabel,
  WeakG
} from './common/global-components'


const app = createApp(App)
// レイアウト
app.component('strong-g', StrongG)
app.component('medium-g', MediumG)
app.component('weak-g', WeakG)
app.component('strong-hr', StrongHr)
app.component('medium-hr', MediumHr)

// テキスト @see フォントのルール
// タイトル
app.component('main-title', MainTitle)
app.component('sub-title', SubTitle)

// ラベル、文章
app.component('description', Description)
// Label(<label/>)のみmain-labelにもしたくないし、cssで定義している。

// 補助ラベル、文章
app.component('sup-label', SupLabel)
app.component('sup-description', SupDescription)

// Roboto
app.component('roboto', Roboto)
app.component('small-text', SmallText)

// Spacer
app.component('spacer', Spacer)


app.directive('click-outside', {
  beforeMount(el, binding) {
    // 全てのClickをListenする
    document.addEventListener('click', (event) => {
      // クリックした要素がv-outside-clickの要素もしくはその子要素ではない場合callbackを発火する
      if (!el.contains(event.target)) {
        binding.value(event)
      }
    })
  },
  unmounted(el, binding) {
    // FIXME: 関数共通化したいけどやり方わからん
    // あとこれでほんまにremoveできてるのかも怪しい
    document.removeEventListener('click', (event) => {
      if (!el.contains(event.target)) {
        binding.value(event)
      }
    })
  }
})
app.use(createPinia().use(PiniaFirestoreSync)).mount('#app')
