<template>
  <p class="description"><slot /></p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Description'
})
</script>

<style scoped>
.bold {
  font-weight: var(--font-weight-bold);
}
.description {
  font-size: var(--font-size-16px);
  font-weight: var(--font-weight-regular);
}
.gray-1 {
  color: var(--font-gray-1);
}
.gray-3 {
  color: var(--font-gray-3);
}
.main-title + .description {
  margin-top: var(--base-4px);
}
.sub-title + .description {
  margin-top: var(--base-4px);
}
</style>
