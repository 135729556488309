import { Directive } from 'vue'
// v-keydown:Escape="function"
const directive: Directive = {
  beforeMount(el, binding) {
    document.addEventListener('keydown', (event) => {
      if (event.key === binding.arg) {
        binding.value()
      }
    })
  },
  unmounted(el, binding) {
    document.removeEventListener('keydown', (event) => {
      if (event.key === binding.arg) {
        binding.value()
      }
    })
  }
}
export const keydown = { keydown: directive }
