<template>
  <h1 class="main-title"><slot /></h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MainTitle'
})
</script>

<style scoped>
.main-title {
  font-size: var(--font-size-24px);
  /* boldのみ */
  font-weight: var(--font-weight-bold);
}
</style>
