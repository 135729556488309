import { buildOauthStateString } from "@WombatTechnology/nitte-time-lib"
export const auth = () => {
  const stateString = buildOauthStateString(true, "text-gen", null, "")
  window.location.href = `${
    import.meta.env.VITE_APP_CLOUD_FUNCTION_URL
  }/authenticate?state=${stateString}`
}

export const authMs = () => {
  const stateString = buildOauthStateString(true, "text-gen", null, "")
  window.location.href = `${
    import.meta.env.VITE_APP_CLOUD_FUNCTION_URL
  }/auth_ms?state=${stateString}`
}
