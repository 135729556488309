<template>
  <div class="position-absolute" :style="coordinate">
    <div class="event-period-base all-day-event-period" @click="onClickEvent">
      <small-text data-private class="eventl-label mg-left-4px">{{ allDayEvent.event.title }}</small-text>
    </div>
  </div>
</template>

<script lang="ts">
import { openExternalLink } from '@/common/helpers/openExternalLink'
import { useCalendarStore } from '@/PC/store/calendarStore'

import {
  AllDayEventCoordinateCoder, NitteCalendarAllDayEvent, toPx
} from '@WombatTechnology/nitte-time-lib'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AllDayEventPeriod',
  data() {
    return {
      selectingInSp: false
    }
  },
  props: {
    allDayEvent: {
      required: true,
      type: Object as PropType<NitteCalendarAllDayEvent>
    }
  },
  computed: {
    coordinate() {
      const coordinate = AllDayEventCoordinateCoder.encode(
        this.allDayEvent,
        useCalendarStore().coordinateConfig
      )
      return toPx(coordinate)
    }
  },
  methods: {
    onClickEvent() {
      openExternalLink(this.$props.allDayEvent.event.htmlLink ?? '', true)
    }
  }
})
</script>

<style scoped>
.all-day-event-period {
  border: 0.5px solid var(--border-gray);
}
.eventl-label {
  padding-left: var(--pd-4px);
}
/* EventPeriodのコピペ 🙏*/
.event-tool-tip /deep/ .message {
  padding: 4px;
  display: flex;
}
.event-tool-tip /deep/ .triangle {
  margin-left: 15px;
}
.external-link {
  height: 16px;
  width: 16px;
}
.sp-selected {
  background-color: var(--accent);
}
</style>
