import { format } from 'date-fns'

/**
 * ブラウザのデフォルトのタイムゾーンを取得する
 * @returns IANA Time Zone Database name
 * ex) Asia/Tokyo
 */
export const browserTimeZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const timeZoneLabel = () => format(new Date(), 'zzz')
