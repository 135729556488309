<script setup lang="ts">
import { useInputStore } from '@/SP/store/InputStore';
import { formatHourMin } from '@WombatTechnology/nitte-time-lib';
import { eachMinuteOfInterval, endOfDay, isSameHour, isSameMinute, startOfDay } from 'date-fns';
import { storeToRefs } from 'pinia';
import { onMounted, Ref, ref } from 'vue';

const timeInput = ref<Ref<HTMLElement> | null>(null)
onMounted(() => {
  console.log("timeInput", timeInput.value)
  if (timeInput.value) {
    timeInput.value.scrollLeft = 800
  }
  // timeInput.value.scroll
})

const emit = defineEmits<{
  (e: 'select-time', date: Date): void
}>()

// NOTE: Hour,Minしか使わないので、今日で初期化する
const start = startOfDay(new Date())
const end = endOfDay(new Date())
const times = eachMinuteOfInterval({
  start,
  end
}, { step: 30 })

const inputStore = useInputStore()
const { currentEditingDateTime } = storeToRefs(inputStore)

const isStartTime = (time: Date) => {
  const startTime = currentEditingDateTime.value?.startTime
  return startTime && isSameHour(startTime, time) && isSameMinute(startTime, time)
}
</script>

<template>
  <ul class="time-input scroll fill-wt" ref="timeInput">
    <button
      @click="emit('select-time', time)"
      class="time-button pd-row"
      :class="{
        'start-time': isStartTime(time)
      }"
      v-for="time in times"
      :key="time.toISOString()"
    >
      <label>{{ formatHourMin(time) }}</label>
    </button>
  </ul>
</template>

<style scoped>
.time-button {
  background-color: white;
  height: 48px;
  box-shadow: 0px 1.95467px 0.977333px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.time-button > label {
  font-size: 18px;
}
.time-input {
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-rows: 52px 52px;
  grid-template-columns: repeat(24, 1fr);
  column-gap: var(--base-12px);
  /* row-gap: var(--base-4px); */
  padding: 0 var(--base-16px);
}
.start-time {
  background-color: var(--main);
  color: white;
}
</style>