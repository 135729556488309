import { fetchUserEvents } from "@/common/helpers/apiClient"
import {
  AllDayEventClamper,
  AllDayEventDecorator,
  Coordinate,
  CoordinateConfig,
  NitteCalendarAllDayEvent,
  NitteCalendarEvent,
  NitteCalendarTimeLineEvent,
  OverdayEventDivider,
  Period,
  PeriodCoordinateCoder,
  TimeLineEventDecorator,
} from "@WombatTechnology/nitte-time-lib"
import {
  addDays,
  addMonths,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns"
import { partition } from "lodash"
import { defineStore } from "pinia"
import { useUserStore } from "./userStore"
export type State = {
  // 現在表示しているページの最初の日付
  currentPageFirstDate: Date
  // 表示する日付の数
  // PC: 7日
  // スマホ: 3日
  displayDateCountPerPage: 3 | 7
  // 現在の月カレンダーの月の基準日時
  currentMonthlyCalendarBaseDate: Date
  // ユーザータイムラインの予定
  timeLineEvents: NitteCalendarTimeLineEvent[]
  // ユーザーの終日の予定
  dayLineEvents: NitteCalendarAllDayEvent[]
  // 月のカレンダーを表示するか
  showMonthlyCalendar: boolean
  // 1日のセルの横幅ppx
  widthPerDay: number
  // 1日のイベントの高さ
  dayEventHeight: number
  // 1時間のセルの縦幅px
  hourHeight: number

  // 選択する時の移動単位分
  selectBaseMin: number

  // 日本の祝日
  japaneseHolidays: NitteCalendarEvent[] | null

  showUserEvents: boolean

  representativeCalendarTimeZone: string | null

  timelineX: number
  timelineY: number
  isCursorInTimeline: boolean
}

export const useCalendarStore = defineStore("calendar", {
  state: (): State => {
    return {
      currentPageFirstDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
      displayDateCountPerPage: 7,
      currentMonthlyCalendarBaseDate: startOfMonth(new Date()),
      timeLineEvents: [],
      dayLineEvents: [],
      showMonthlyCalendar: false,
      widthPerDay: 110,
      dayEventHeight: 20,
      hourHeight: 43,
      selectBaseMin: 30,
      japaneseHolidays: null,
      showUserEvents: false,
      representativeCalendarTimeZone: null,
      timelineX: 0,
      timelineY: 0,
      isCursorInTimeline: false,
    }
  },
  actions: {
    moveToDate(date: Date) {
      if (this.displayDateCountPerPage === 7) {
        // 7日の場合は月曜日を起点に表示
        this.$patch({
          currentPageFirstDate: startOfWeek(date, { weekStartsOn: 1 }),
        })
      } else {
        // 3日の場合はそのまま表示
        this.$patch({ currentPageFirstDate: startOfDay(date) })
      }
    },
    moveToNextPage() {
      const nextPageFirstDate = addDays(
        this.currentPageFirstDate,
        this.displayDateCountPerPage
      )
      this.$patch({ currentPageFirstDate: nextPageFirstDate })
    },
    moveToPreviousPage() {
      const nextPageFirstDate = subDays(
        this.currentPageFirstDate,
        this.displayDateCountPerPage
      )
      this.$patch({ currentPageFirstDate: nextPageFirstDate })
    },
    toggleMonthlyCalendar() {
      this.showMonthlyCalendar = !this.showMonthlyCalendar
      this.$patch({
        currentMonthlyCalendarBaseDate: startOfMonth(this.currentPageFirstDate),
      })
    },
    isHoliday(date: Date): boolean {
      return false
      // TODO
      // if (isNull(this.japaneseHolidays)) {
      //   return false
      // }
      // return this.japaneseHolidays.some((event: NitteCalendarEvent) =>
      //   isSameDay(event.period.start, date)
      // )
    },
    // アクセスしているユーザーのカレンダーの予定を取得
    async fetchUserEvents() {
      if (!useUserStore().isCalendarScopeAuthorized) {
        return
      }
      const start = new Date()
      const end = addMonths(start, 3)
      const events = await fetchUserEvents(start || new Date(), end)
      const [rawAllDayEvents, rawTimeLineEvents] = partition(
        events,
        (ev) => ev.isAllDay
      )
      const timeLineEvents = TimeLineEventDecorator.decorate(
        // 日を跨ぐイベントを分割する
        OverdayEventDivider.divideAll(rawTimeLineEvents)
      )

      const dayLineEvents = AllDayEventDecorator.decorate(rawAllDayEvents)
      this.$patch({ timeLineEvents, dayLineEvents })
    },
  },
  getters: {
    pageWidth(state) {
      return state.widthPerDay * state.displayDateCountPerPage
    },
    pageHeight(state) {
      return state.hourHeight * 24
    },
    coordinateConfig(state): CoordinateConfig {
      return {
        originDate: this.currentPagePeriod.start,
        heightPerMinute: this.heightPerMinute,
        widthPerDay: state.widthPerDay,
        dayEventHeight: state.dayEventHeight,
      }
    },
    heightPerMinute(state) {
      return state.hourHeight / 60
    },
    currentPagePeriod(state: State): Period {
      return new Period(state.currentPageFirstDate, this.currentPageLastDate)
    },
    currentPageLastDate(state) {
      return endOfDay(
        addDays(state.currentPageFirstDate, state.displayDateCountPerPage - 1)
      )
    },
    currentCusrorDate() {
      const period = PeriodCoordinateCoder.decode(
        this.currentCursorCoordinate,
        this.coordinateConfig,
        15
      )
      return period.start
    },
    currentCursorCoordinate(): Coordinate {
      return {
        left: this.timelineX,
        top: this.timelineY,
        width: this.widthPerDay,
        height: this.hourHeight,
      }
    },
    currentPeriodTimeLineEvents(state): NitteCalendarTimeLineEvent[] {
      return state.timeLineEvents.filter((te: NitteCalendarTimeLineEvent) => {
        return te.event.period.isSubsetOf(this.currentPagePeriod)
      })
    },
    currentPeriodDayLineEvents(state): NitteCalendarAllDayEvent[] {
      return AllDayEventClamper.clampAll(
        this.dayLineEvents,
        this.currentPagePeriod.start,
        this.currentPagePeriod.end
      ).filter((de) => {
        return de.event.period.isSubsetOf(this.currentPagePeriod)
      })
    },
  },
})
