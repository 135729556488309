import {
  startOfMonth,
  startOfWeek,
  endOfMonth,
  endOfWeek,
  eachDayOfInterval,
  isBefore
} from 'date-fns'
import startOfDay from 'date-fns/startOfDay'
import { chunk } from 'lodash'

export const isInCurrentDisplayMonth = (
  currentDate: Date,
  targetDate: Date
): boolean => {
  return currentDate.getMonth() !== targetDate.getMonth()
}

export const isPast = (targetDate: Date): boolean =>
  isBefore(startOfDay(targetDate), startOfDay(new Date()))

/**
 * 日付からその月のカレンダーに表示する1ヶ月分の7日毎のDateの2次元配列を生成する
 * @param date
 * @returns
 */
export const dateToCalendarMonthlyWeeks = (date: Date): Date[][] => {
  const startOfWeekOfStartOfMonth = startOfWeek(startOfMonth(date), {
    weekStartsOn: 1
  })
  const endOfWeekOfEndOfMonth = endOfWeek(endOfMonth(date), {
    weekStartsOn: 1
  })
  const dates = eachDayOfInterval({
    start: startOfWeekOfStartOfMonth,
    end: endOfWeekOfEndOfMonth
  })
  return chunk(dates, 7)
}
