<script setup lang="ts">
import { computed, toRefs, useSlots } from 'vue';
interface Props {
  src?: string,
  rect: string
}

const props = withDefaults(
  defineProps<Props>(),
  {
    rect: '40px'
  }
)
const { src, rect } = toRefs(props)
const hasSlot = computed(() => !!useSlots().default)
</script>

<template>
  <div class="lo-v-center">
    <slot />
    <!-- srcがある場合 -->
    <img
      v-if="src"
      class="user-icon fill-wt-ht clip-circle object-fit-conver"
      :class="{
        'mg-top-4px': hasSlot
      }"
      keep-show-menu="1"
      :src="src"
      :style="{
        height: rect,
        width: rect
      }"
    />
    <!-- 
      ない場合はゲストアイコン
      srcのパスをpropsから渡そうとするとハッシュ値が付与されずにプロダクションで
      表示されなくなるので注意
    -->
    <img
      v-else
      class="user-icon fill-wt-ht clip-circle object-fit-conver"
      :class="{
        'mg-top-4px': hasSlot
      }"
      keep-show-menu="1"
      src="@/assets/guest-user.svg"
      :style="{
        height: rect,
        width: rect
      }"
    />
  </div>
</template>



<style scoped>
/* CSS Here */
</style>
