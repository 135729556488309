<template>
  <medium-g><hr /></medium-g>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MediumHr'
})
</script>
