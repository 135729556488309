import gsap from "gsap";
import { defineStore } from "pinia";

export enum NotificationType {
  Success = 'success',
  Error = 'error'
}


// State
export type Notification = {
  bottomPosition: number,
}
export type State = Notification

const animationDuration = 0.3
const showPosition = 0
const hidePosition = -158
const durationForAutoClose = 3000

export const useSpNotificationStore = defineStore('sp-notification', {
  state: (): State => {
    return {
      bottomPosition: hidePosition
    }
  },
  actions: {
    showNotification(
      autoClose: boolean = true
    ) {
      // 表示アニメーション
      gsap.to(this, {
        duration: animationDuration,
        bottomPosition: showPosition,
        ease: 'easeOut'
      })
      if (autoClose) {
        setTimeout(() => {
          this.hideNotification()
        }, durationForAutoClose)
      }
    },
    hideNotification() {
      gsap.to(this, {
        duration: animationDuration,
        bottomPosition: hidePosition,
        ease: 'easeOut'
      })
    }
  }
})