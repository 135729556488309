

<script setup lang="ts">
import '@/common/extensions/number'
import { useCalendarStore } from '@/PC/store/calendarStore'
import { usePeriodsStore } from '@/PC/store/periodsStore'
import { formatWithLocale, Period } from '@WombatTechnology/nitte-time-lib'
import { endOfDay, isSameDay, isSaturday, isSunday, isToday, startOfDay } from 'date-fns'
import { storeToRefs } from 'pinia'
import { computed, toRefs } from 'vue'

interface Props {
  date: Date
}
const props = defineProps<Props>();
const { date } = toRefs(props)

const periodsStore = usePeriodsStore()
const { dayPeriodsDates } = storeToRefs(periodsStore)


const calendarStore = useCalendarStore()
const { widthPerDay } = storeToRefs(calendarStore)

const isSelected = computed(() => {
  return !!dayPeriodsDates.value.find(d => isSameDay(d, date.value))
})

const onClickDate = (date: Date) => {
  const datePeriod = new Period(startOfDay(date), endOfDay(date))
  // すでに選択している場合
  if (isSelected.value) {
    console.log("delete")
    // 削除して終了
    periodsStore.remove(datePeriod)

    console.log(periodsStore.periods)
    return
  }
  // 追加してない場合
  periodsStore.$patch((state) => {
    state.periods.push(datePeriod)
  })
}
</script>


<template>
  <button
    @click="onClickDate(date)"
    class="date-button day-label lo-center position-relative"
    :class="{
      'border-right': isSunday(date),
      selected: isSelected
    }"
    :style="{ width: widthPerDay.toPx() }"
  >
    <div class="button-inner lo-v-center">
      <!-- 曜日 -->
      <sup-label
        :class="{
          'today-dayname': isToday(date),
          'or-ft-saturday-blue': !isToday(date) && isSaturday(date),
          'or-ft-sunday-red': isSunday(date),
          'or-ft-holiday-red': calendarStore.isHoliday(date),
        }"
        class="day-label-dayname bold gray-1 mg-top-8px"
      >{{ formatWithLocale(date, 'E') }}</sup-label>
      <!-- 日付 -->
      <div
        class="day-label-daynum daynum-wrapper lo-center"
        :class="{
          'today-day-num-circle': isToday(date)
        }"
      >
        <roboto
          class="day-num size-20px gray-1 medium"
          :class="{
            'today-daynum': isToday(date)
          }"
        >{{ date.getDate() }}</roboto>
      </div>
    </div>
    <img class="close-button position-absolute" src="@/assets/close.svg" />
  </button>
</template>

<style scoped>
.timzone-label {
  width: var(--hour-label-width);
  margin-right: 4px;
  margin-bottom: 2px;
}
.day-label {
  height: 70px;
  border-left: 1px solid var(--border-black);
}
.border-right {
  border-right: 1px solid var(--border-black);
}
.daynum-wrapper {
  width: 34px;
  height: 34px;
}
.today-day-num-circle {
  border-radius: 32px;
  background-color: var(--bg-emphasis-1);
}
.today-dayname {
}
.today-daynum {
  color: var(--main) !important;
}

.vertical-line:last-of-type {
  border-right: 1px solid var(--border-black);
}

.date-button.selected {
}

.date-button.selected .button-inner {
  background-color: var(--main);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 2px;
}

.date-button.selected span {
  color: white;
}
.date-button.selected span.or-ft-sunday-red {
  color: white !important;
}
.date-button.selected .or-ft-saturday-blue {
  color: white !important;
}
.date-button.selected p {
  color: white;
  font-weight: bold;
}

.date-button.selected:hover {
}

.date-button.selected:hover .close-button {
  display: unset;
}

/* Responsive */
@media screen and (max-width: 800px) {
  .day-label-dayname {
    font-size: var(--font-size-14px);
  }
  .day-label-daynum {
    font-size: var(--font-size-16px);
  }
}

.date-button:hover {
  background-color: rgb(154, 154, 154, 0.2);
}

.close-button {
  /* 調整値 */
  top: -4px;
  right: 1px;
  display: none;
}
</style>