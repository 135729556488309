import { uid as uidFunc } from "@/common/helpers/firebase"
import { toData } from "@/common/helpers/toData"
import { UserPublicProfile } from "@/common/helpers/userPublicProfile"
import { FSUserPublicProfile } from "@WombatTechnology/nitte-time-lib"
import { defineStore } from "pinia"

// State
export type State = {
  userPublicProfile: FSUserPublicProfile | null
}

export const useUserStore = defineStore("user", {
  state: (): State => {
    return {
      userPublicProfile: null,
    }
  },
  actions: {
    async setup() {
      const uid = uidFunc()!
      // 初期化
      const doc = UserPublicProfile.doc(uid)
      const userPublicProfile = await toData(doc)
      this.userPublicProfile = userPublicProfile

      this.sync("userPublicProfile", doc)
    },
  },
  getters: {
    isLoggedIn(state): boolean {
      return !!state.userPublicProfile
    },
    userIcon(state) {
      return state.userPublicProfile?.icon
    },
    isCalendarScopeAuthorized(state) {
      return UserPublicProfile.isCalendarScopeAuthorized(
        state.userPublicProfile
      )
    },
  },
})
