import { store } from "@/common/helpers/firebase"
import {
  CollectionName,
  FSUserPublicProfile,
} from "@WombatTechnology/nitte-time-lib"
import {
  CollectionReference,
  DocumentReference,
  collection,
  doc,
} from "firebase/firestore"

export class UserPublicProfile {
  static doc(uid: string) {
    return doc<FSUserPublicProfile>(
      this.collection(),
      uid
    ) as DocumentReference<FSUserPublicProfile>
  }
  static collection() {
    return collection(
      store,
      CollectionName.UserPublicProfiles
    ) as CollectionReference<FSUserPublicProfile>
  }
  static isCalendarScopeAuthorized(data: FSUserPublicProfile | null) {
    // NOTE: MSの場合は部分的に承認することができないので、登録している時点でtrue
    if (data?.accountType === "ms") {
      return true
    }
    const scope = data?.scope
    const hasV1Access = scope?.includes(
      "https://www.googleapis.com/auth/calendar"
    )
    const hasV2Access =
      scope?.includes("https://www.googleapis.com/auth/calendar.events") &&
      scope?.includes("https://www.googleapis.com/auth/calendar.readonly")
    return hasV1Access || hasV2Access
  }
}
