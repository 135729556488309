
<script setup lang="ts">
import { useCalendarStore } from "@/PC/store/calendarStore";
import { usePeriodsStore } from "@/PC/store/periodsStore";
import {
  Coordinate,
  Period,
  PeriodCoordinateCoder,
} from "@WombatTechnology/nitte-time-lib";
import { storeToRefs } from "pinia";
import CursorTime from "./CursorTime.vue";
import EventPeriod from "./EventPeriod.vue";
import SelectPeriod from "./SelectPeriod.vue";

const calendarStore = useCalendarStore();
const {
  pageHeight,
  pageWidth,
  hourHeight,
  widthPerDay,
  displayDateCountPerPage,
  coordinateConfig,
  isCursorInTimeline,
  currentPeriodTimeLineEvents,
  currentPagePeriod
} = storeToRefs(calendarStore);

const selectPeriodsStore = usePeriodsStore();
const { periods, editing, isEditing, timelinePeriods } = storeToRefs(selectPeriodsStore);

const mouseMove = (timelineX: number, timelineY: number) => {
  // 常にcursorの位置を保存しておく(重くなるようならやめる)
  calendarStore.$patch({
    timelineX,
    timelineY,
  });
  if (selectPeriodsStore.isEditing) {
    if (editing.value?.editTime === 'start') {
      selectPeriodsStore.updatePeriodStart();
    }
    if (editing.value?.editTime === 'end') {
      selectPeriodsStore.updatePeriodEnd();
    }
  }
};

const calcPeriodCoordinate = (period: Period): Coordinate => {
  const coordinate = PeriodCoordinateCoder.encode(
    period,
    coordinateConfig.value
  );
  // 密着しないための調整値
  coordinate.width -= 1;
  return coordinate;
};

</script>

<template>
  <div
    draggable="false"
    ondragstart="return false"
    class="time-line bg-white"
    :style="{
      width: pageWidth.toPx(),
      height: pageHeight.toPx(),
      cursor: isEditing ? 'ns-resize' : 'pointer'
    }"
    @mousedown="selectPeriodsStore.createPeriod()"
    @mouseup="selectPeriodsStore.endEditing()"
    @mousemove="(ev: any) => mouseMove(ev.layerX, ev.layerY)"
    @mouseenter="calendarStore.$patch({ isCursorInTimeline: true })"
    @mouseleave="calendarStore.$patch({ isCursorInTimeline: false })"
  >
    <!-- Horizontal Line -->
    <div class="horizontal-lines fill-wt-ht">
      <div
        class="horizontal-line"
        v-for="idx in 24"
        :key="idx"
        :style="{ height: hourHeight.toPx() }"
      />
    </div>
    <!-- Vertical Lines -->
    <div class="vertical-lines lo-h-inline-flex-start fill-wt-ht">
      <div
        class="vertical-line fill-ht"
        :style="{ width: widthPerDay.toPx() }"
        v-for="idx in displayDateCountPerPage"
        :key="idx"
      />
    </div>
    <event-period
      :time-line-event="timeLineEvent"
      :startDate="currentPagePeriod.start"
      v-for="timeLineEvent in currentPeriodTimeLineEvents"
      :key="timeLineEvent.event.id"
    ></event-period>
    <!-- CurrentTimeIndicator -->
    <current-time-indicator />
    <cursor-time v-show="!isEditing && isCursorInTimeline" />
    <!-- SelectPeriod -->
    <!-- 
      NOTE: 
      indexでeditingを引きたい都合上periods全て渡してshownを見てコンポーネント側で
      終日を除外する。
    -->
    <select-period
      @mousemove="mouseMove"
      :key="index"
      v-for="(period, index) in periods"
      :period="period"
      :is-editing="editing?.index === index"
      :shown="timelinePeriods.includes(period)"
      :coordinate="calcPeriodCoordinate(period)"
    />
  </div>
</template>

<style scoped>
/* CSS Here */
.time-line {
  position: relative;
  border-bottom: 1px solid;
  border-color: var(--border-black);
  cursor: pointer;
}

/* Horizontal */
.horizontal-lines {
  position: absolute;
}
.horizontal-line {
  border-top: 1px solid;
  border-color: var(--border-black);
}

.vertical-lines {
  position: absolute;
}
.vertical-line {
  border-left: 1px solid;
  border-color: var(--border-black);
}
.vertical-line:last-of-type {
  border-right: 1px solid;
  border-color: var(--border-black);
}
</style>
