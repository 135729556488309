import { auth } from '@/common/helpers/firebase'
import { formatFullDate, NitteCalendarEvent } from '@WombatTechnology/nitte-time-lib'
import axios from 'axios'
import { isNull } from 'lodash'
import {
  EventResponse, parseEventResponse
} from './apiResponseParser'


export const getIdToken = async (): Promise<string> => {
  const user = auth.currentUser
  if (isNull(user)) {
    return ''
  }
  // WARNING:
  // このflagをtrueにしているとメソッドをコールした瞬間に
  // エラーなしでDOMが全て消失してしまうバグがある。
  return user.getIdToken(false)
}

export const fetchUserEvents = async (
  start: Date,
  end: Date
): Promise<NitteCalendarEvent[]> => {
  const idToken = await getIdToken()
  const res = await axios.get<any>(
    `${import.meta.env.VITE_APP_CLOUD_FUNCTION_URL
    }/user_events?start=${formatFullDate(start)}&end=${formatFullDate(end)}`,
    {
      headers: { Authorization: `Bearer ${idToken}` }
    }
  )
  const events = res.data.events as EventResponse[]
  return events.map((ev) => parseEventResponse(ev))
}

export const fetchJapaneseHolidays = async (
  start: Date,
  end: Date
): Promise<NitteCalendarEvent[]> => {
  const res = await axios.get<any>(
    `${import.meta.env.VITE_APP_CLOUD_FUNCTION_URL
    }/national_holidays?start=${formatFullDate(start)}&end=${formatFullDate(
      end
    )}`
  )
  const events = res.data.holidays as EventResponse[]
  return events.map((ev) => parseEventResponse(ev))
}