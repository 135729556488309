<template>
  <img
    @click.prevent="close"
    v-keydown:Escape="close"
    class="close-button pointer hover-ripple"
    src="@/assets/close-modal.svg"
    :style="{
      width: rect,
      height: rect,
      position,
      right: right || ($root.isMobile ? '20px' : '32px'),
      top: top || ($root.isMobile ? '20px' : '24px')
    }"
  />
</template>

<script lang="ts">
import { keydown } from '@/common/directives/v-keydown'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'nitCloseButton',
  props: {
    position: {
      type: String,
      default: 'absolute'
    },
    rect: {
      type: String,
      default: '20px'
    },
    top: {
      type: String,
      default: null
    },
    right: {
      type: String,
      default: null
    }
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close')
    }
  },
  directives: {
    ...keydown
  }
})
</script>

<style scoped>
/* CSS Here */
</style>
