<template>
  <div class="monthly-calendar bg-sub-1 border-black bd-6px pd-rect shadow-high">
    <!-- Header control -->
    <div class="controller lo-h-center fill-wt no-select">
      <button class="lo-v" @click="moveToPreviousMonth">
        <img class="flip" src="@/assets/arrow.svg" />
      </button>
      <div class="lo-h-center">
        <roboto>{{ currentMonthlyCalendarBaseDate.getFullYear() }}</roboto>
        <small-text>年</small-text>
        <roboto>{{ currentMonthlyCalendarBaseDate.getMonth() + 1 }}</roboto>
        <small-text>月</small-text>
      </div>
      <button class="lo-v" @click="moveToNextMonth">
        <img src="@/assets/arrow.svg" />
      </button>
    </div>
    <!-- Calendar content -->
    <div class="monthly-calendar-content mg-top-12px">
      <sup-label class="day-names lo-h-inline-flex-start fill-wt no-select">
        <sup-label class="day-name">月</sup-label>
        <sup-label class="day-name">火</sup-label>
        <sup-label class="day-name">水</sup-label>
        <sup-label class="day-name">木</sup-label>
        <sup-label class="day-name">金</sup-label>
        <sup-label class="or-ft-saturday-blue day-name">土</sup-label>
        <sup-label class="or-ft-sunday-red day-name">日</sup-label>
      </sup-label>
      <div class="weeks fill-wt">
        <div
          class="week lo-h-inline-flex-start"
          v-for="(week, index) in currentMonthlyCalendarWeeks"
          :key="index"
          :class="{ 'this-week': isThisWeek(week) }"
        >
          <div
            class="day-num pointer"
            v-for="(date, index) in week"
            @click="
              () => {
                moveToDate(date)
                toggleMonthlyCalnedar()
              }
            "
            :key="index"
            :class="{
              today: isToday(date),
              past: isPast(date),
              'not-in-display-month': isInCurrentDisplayMonth(currentMonthlyCalendarBaseDate, date),
              'current-display': !isThisWeek(week) && isDisplayed(date)
            }"
          >
            <div v-if="isToday(date)" class="today-circle"></div>
            <roboto
              class="regular"
              :class="{
                today: isToday(date)
              }"
            >{{ date.getDate() }}</roboto>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  dateToCalendarMonthlyWeeks,
  isInCurrentDisplayMonth,
  isPast
} from '@/common/helpers/monthlyCalendarHelper'
import { useCalendarStore } from '@/PC/store/calendarStore'
import { addMonths, isToday, isWithinInterval, subMonths } from 'date-fns'
import { first, last } from 'lodash'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MonthlyCalendar',
  emits: ['select-date'],
  data() {
    return {
      isInCurrentDisplayMonth,
      isPast,
      isToday
    }
  },
  computed: {
    ...mapState(useCalendarStore, [
      'currentMonthlyCalendarBaseDate',
      'currentPagePeriod'
    ]),
    currentMonthlyCalendarWeeks: () => {
      return dateToCalendarMonthlyWeeks(useCalendarStore().currentMonthlyCalendarBaseDate)
    }
  },
  methods: {
    isThisWeek(week: Date[]) {
      return isWithinInterval(new Date(), {
        start: first(week)!,
        end: last(week)!
      })
    },
    isDisplayed(date: Date) {
      return isWithinInterval(date, {
        start: this.currentPagePeriod.start,
        end: this.currentPagePeriod.end
      })
    },
    moveToDate(date: Date) {
      this.$emit('select-date', date)
      useCalendarStore().moveToDate(date)
    },
    moveToNextMonth() {
      const store = useCalendarStore()
      store.currentMonthlyCalendarBaseDate = addMonths(
        store.currentMonthlyCalendarBaseDate,
        1
      )
    },
    moveToPreviousMonth() {
      const store = useCalendarStore()
      store.currentMonthlyCalendarBaseDate = subMonths(
        store.currentMonthlyCalendarBaseDate,
        1
      )
    },
    toggleMonthlyCalnedar() {
      useCalendarStore().toggleMonthlyCalendar()
    }
  }
})
</script>

<style scoped src="/src/common/styles/common/monthly_calendar.css"></style>
<style scoped>
.today > p {
  color: var(--main);
}
.today-circle {
  background: var(--bg-emphasis-1);
}
</style>
