<template>
  <!-- 一旦レスポンシブで頑張ってる状態。しんどくなったら分けてOK -->
  <nit-modal @blur="$emit('close')">
    <div class="position-relative scroll-auto modal-margin" :style="{ 'max-width': maxWidth }">
      <nit-close-button v-if="showCloseButton" @close="() => $emit('close')" />
      <div
        class="modal-content-section lo-v-center"
        :class="{
          'no-button-section': !hasButton
        }"
      >
        <main-title class="title">
          <slot name="title" />
          <p>{{ title }}</p>
        </main-title>
        <description class="description mg-top-12px gray-1">
          <slot name="description"></slot>
        </description>
        <div class="contents fill-wt lo-v-center mg-top-24px">
          <slot name="contents"></slot>
        </div>
      </div>
      <div v-if="hasButton" class="modal-button-section">
        <div v-if="hasSecondButtonSlot" class="lo-h-inline-flex-start fill-wt">
          <div class="second-button">
            <slot name="second-button"></slot>
          </div>
          <div class="first-button mg-left-12px">
            <slot name="first-button"></slot>
          </div>
        </div>
        <div v-else class="lo-v-center fill-wt">
          <slot name="first-button"></slot>
        </div>
      </div>
    </div>
  </nit-modal>
</template>
<script lang="ts">
import { isNotNil } from '@/common/helpers/lodash-ext'
import { defineComponent } from 'vue'
import NitCloseButton from './NitButton/NitCloseButton.vue'
import NitModal from './NitModal.vue'
export default defineComponent({
  name: 'ModalWithButtons',
  emits: ['close'],
  props: {
    title: String,
    showCloseButton: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String,
      default: '560px'
    },
    fullModalSp: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NitModal,
    NitCloseButton,
  },
  computed: {
    hasFirstButtonSlot(): boolean {
      return isNotNil(this.$slots['first-button'])
    },
    hasSecondButtonSlot(): boolean {
      return isNotNil(this.$slots['second-button'])
    },
    hasButton(): boolean {
      return this.hasFirstButtonSlot || this.hasSecondButtonSlot
    }
  }
})
</script>

<style scoped>
/* Mobile */
.modal-with-buttons-sp {
  width: calc(100vw - 32px);
  margin: var(--base-40px) 0;
  max-height: 100vh;
  overflow-y: auto;
}
.modal-with-buttons-sp.full-modal-sp {
  width: 100%;
  margin: 0;
}
.modal-with-buttons-sp.full-modal-sp {
  height: 100%;
}
.second-button,
.first-button {
  width: 100%;
}
/* Desktop */
.modal-with-buttons-pc {
  margin-top: var(--base-120px);
}
/* 縦幅の狭いパソコンはmargin-topを詰める */
@media screen and (max-height: 800px) {
  .modal-with-buttons-pc {
    margin-top: var(--base-20px);
  }
}
</style>
