interface Number {
  toPx: () => string
  toFullHour: () => string
}

// eslint-disable-next-line no-extend-native
Number.prototype.toPx = function (): string {
  return String(this) + 'px'
}

// eslint-disable-next-line no-extend-native
Number.prototype.toFullHour = function (): string {
  return this < 10 ? '0' + this.toString() : this.toString()
}
