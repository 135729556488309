<template>
  <span class="sup-label">
    <slot />
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SupLabel'
})
</script>

<style scoped>
.sup-label {
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-medium);
}
.gray-1 {
  color: var(--font-gray-1);
}
.white {
  color: var(--font-white);
}
</style>
