<script setup lang="ts">
import {
  dateToCalendarMonthlyWeeks, isInCurrentDisplayMonth,
  isPast
} from '@/common/helpers/monthlyCalendarHelper';
import { useCalendarStore } from '@/PC/store/calendarStore';
import { useInputStore } from '@/SP/store/InputStore';
import { addMonths, isSameDay, isToday, subMonths } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const calendarStore = useCalendarStore()
const { currentMonthlyCalendarBaseDate } = storeToRefs(calendarStore)

const emit = defineEmits<{
  (e: 'select-date', date: Date): void
}>()

// Computed
const currentMonthlyCalendarWeeks = computed(() => {
  return dateToCalendarMonthlyWeeks(useCalendarStore().currentMonthlyCalendarBaseDate)
})

const moveToDate = (date: Date) => {
  emit('select-date', date)
}

const moveToNextMonth = () => {
  const store = useCalendarStore()
  store.currentMonthlyCalendarBaseDate = addMonths(
    store.currentMonthlyCalendarBaseDate,
    1
  )
}
const moveToPreviousMonth = () => {
  const store = useCalendarStore()
  store.currentMonthlyCalendarBaseDate = subMonths(
    store.currentMonthlyCalendarBaseDate,
    1
  )
}

const inputStore = useInputStore()
const { currentEditingDateTime } = storeToRefs(inputStore)

const isSelecting = (date: Date) => {
  const selectingDate = currentEditingDateTime.value?.date
  return selectingDate && isSameDay(selectingDate, date)
}
</script>

<template>
  <div class="monthly-calendar beg-sub-1 bd-6px lo-v-center">
    <!-- Header control -->
    <div class="controller lo-h-center fill-wt no-select">
      <button class="lo-v" @click="moveToPreviousMonth">
        <img class="flip" src="@/assets/arrow.svg" />
      </button>
      <div class="month-label lo-h-center">
        <roboto>{{ currentMonthlyCalendarBaseDate.getFullYear() }}</roboto>
        <small-text>年</small-text>
        <roboto>{{ currentMonthlyCalendarBaseDate.getMonth() + 1 }}</roboto>
        <small-text>月</small-text>
      </div>
      <button class="lo-v" @click="moveToNextMonth">
        <img src="@/assets/arrow.svg" />
      </button>
    </div>
    <!-- Calendar content -->
    <div class="monthly-calendar-content mg-top-8px">
      <sup-label class="day-names lo-h-inline-flex-start fill-wt no-select">
        <sup-label class="day-name">月</sup-label>
        <sup-label class="day-name">火</sup-label>
        <sup-label class="day-name">水</sup-label>
        <sup-label class="day-name">木</sup-label>
        <sup-label class="day-name">金</sup-label>
        <sup-label class="or-ft-saturday-blue day-name">土</sup-label>
        <sup-label class="or-ft-sunday-red day-name">日</sup-label>
      </sup-label>
      <div class="weeks fill-wt">
        <div
          class="week lo-h-inline-flex-start"
          v-for="(week, index) in currentMonthlyCalendarWeeks"
          :key="index"
        >
          <div
            class="day-num pointer"
            v-for="(date, index) in week"
            @click="
              () => {
                moveToDate(date)
              }
            "
            :key="index"
            :class="{
              today: isToday(date),
              past: isPast(date),
              selecting: isSelecting(date),
              'not-in-display-month': isInCurrentDisplayMonth(currentMonthlyCalendarBaseDate, date),
            }"
          >
            <div v-if="isToday(date)" class="today-circle"></div>
            <div v-if="isSelecting(date)" class="selecting-circle"></div>

            <roboto
              class="regular"
              :class="{
                today: isToday(date)
              }"
            >{{ date.getDate() }}</roboto>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.monthly-calendar {
  width: 320px;
}
.month-label {
  font-size: 18px;
}
.day-num {
  width: 40px;
  text-align: center;
}
.day-num > p {
  font-size: 18px;
  font-weight: normal;
}
.day-names .day-name {
  width: 40px;
  text-align: center;
}
.controller {
  justify-content: space-between;
}
.week:nth-of-type(1) {
  margin-top: var(--base-10px);
}
.week {
  margin-top: var(--base-8px);
  height: 21px;
}

.past {
  color: var(--font-gray-3);
  cursor: not-allowed;
}
.not-in-display-month {
  color: var(--font-gray-3);
}
.today {
  position: relative;
}
.today span {
  position: absolute;
  top: 0px;
  z-index: var(--z-index-60);
  width: 100%;
  left: 0;
}
.today-circle {
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 20px;
  left: 8px;
  top: -2px;
}

.selecting {
  position: relative;
  color: white;
}
.selecting > p {
  position: absolute;
  top: 0px;
  z-index: var(--z-index-60);
  width: 100%;
  left: 0;
}
.selecting-circle {
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 20px;
  left: 8px;
  top: -2px;
  background: var(--main);
}
.current-display {
  background: var(--bg-selection-black);
}
.today > p {
  color: var(--main);
}
.today-circle {
  background: var(--bg-emphasis-1);
}
.monthly-calendar-content {
  width: 280px;
}
</style>