<script setup lang="ts">
import { useCalendarStore } from "@/PC/store/calendarStore"
import { usePeriodsStore } from "@/PC/store/periodsStore"
import { useUserStore } from "@/PC/store/userStore"
import { auth } from '@/common/helpers/firebase'
import { onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'
import { onMounted } from "vue"
import { Banner, Calendar, Controller, CopyNotification, NavHeader } from "./components"
import PowerdByNitte from "./components/PowerdByNitte.vue"
import ShowMySchedule from "./components/ShowMySchedule.vue"

onMounted(() => {
  usePeriodsStore().setup()

  const searchParams = new URLSearchParams(window.location.search)
  if (searchParams.has('token')) {
    // tokenが含まれている場合ログインする
    signInWithCustomToken(auth, searchParams.get('token')!).then(async (res) => {
      // tokenを消す
      window.history.replaceState({}, document.title, "/");
    })
  }

  onAuthStateChanged(auth, async (user) => {
    const userStore = useUserStore()
    if (user) {
      await userStore.setup()
      useCalendarStore().fetchUserEvents()
    } else {
      userStore.$patch({ userPublicProfile: null })
      useCalendarStore().$patch({ timeLineEvents: [], dayLineEvents: [] })
    }
  })
})

</script>

<template>
  <!-- SP版 -->
  <!-- PC版 -->
  <main class="fill-vw-vh">
    <NavHeader />
    <section class="container lo-v-center">
      <section>
        <CopyNotification />
        <h3 class="mg-top-24px">カレンダーから選んだ日時を文字に変換します。</h3>
        <section class="contents lo-h bg-white mg-top-16px">
          <div class="calendar-section position-relative">
            <Calendar />
            <ShowMySchedule class="show-my-schedule" />
          </div>
          <div class="lo-v-center">
            <Controller />
            <spacer/>
            <Banner />
            <div class="powered-by-nitte">
              <PowerdByNitte class="mg-top-16px" />
            </div>
          </div>
        </section>
      </section>
    </section>
  </main>
</template>

<style>
.show-my-schedule {
  position: absolute;
  right: var(--base-16px);
  display: inline-block;
  right: 0;
  bottom: 16px;
}
</style>

<style scoped>
.powered-by-nitte {
  padding-bottom: 16px;
}
</style>

