<template>
  <div class="bg-sub-4 bd-6px">
    <div class="calendar">
      <!-- Calendar Header -->
      <div class="calendar-header lo-h-center fill-wt">
        <year-and-month-label class="year-and-month-label" />
        <spacer />
        <controller />
      </div>
      <!-- Calendar Contents -->
      <div class="contents">
        <!-- Day labels -->
        <day-labels></day-labels>
        <!-- Daily Timeline -->
        <day-line />
        <!-- Time lines -->
        <div class="time-lines lo-v" ref="timeline">
          <!-- 上のちょびっと線(TimeLine上) -->
          <div
            class="vertical-lines lo-h-inline-flex-start bg-calendar-unavailable"
            :style="{ width: '770px' }"
          >
            <div
              class="vertical-line fill-ht"
              :style="{ width: '110px', height: '10px' }"
              v-for="idx in 7"
              :key="idx"
            />
          </div>
          <div class="lo-h-inline-flex-start">
            <hour-labels />
            <time-line />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import {
  Controller,
  DayLabels,
  DayLine,
  HourLabels,
  TimeLine,
  YearAndMonthLabel
} from './components'

export default defineComponent({
  name: 'Calendar',
  components: {
    YearAndMonthLabel,
    Controller,
    HourLabels,
    DayLabels,
    TimeLine,
    DayLine
  },
})
</script>

<style scoped>
.calendar {
  padding: var(--base-32px) 0 var(--base-48px) var(--base-64px);
}
.calendar-header {
  height: 48px;
}
.vertical-lines {
  height: var(--base-10px);
  margin-left: var(--hour-label-width);
}
.vertical-line {
  border-left: 1px solid var(--border-black);
}
.time-lines {
  overflow-y: scroll;
  /* 調整値 */
  height: calc(100vh - 400px);
}
.time-lines.embed.onboarding {
  /* モーダル内に入れるのでできるだけ広く取る */
  height: calc(100vh);
}
.label-box {
  margin-left: var(--hour-label-width);
}
.contents {
  margin-top: var(--base-16px);
}

.label-box-sp {
  padding: var(--base-8px) 0;
  margin-left: var(--hour-label-width);
}

/* Responsive */
/* NOTE: Calendarはレイアウトが同じでmedia-query使った方が
シンプルにレスポンシブ対応できるのでmedia-queryを使う */
@media screen and (max-width: 800px) {
  .calendar {
    display: flex;
    flex-direction: column;
  }
  .calendar-header {
    padding: 0 20px;
  }
  .year-and-month-label {
    font-size: var(--font-size-16px);
    font-weight: var(--font-weight-600);
  }
}
.time-lines-sp {
  flex: 1;
  overflow-y: scroll;
}
</style>
