export default () => {
  switch (import.meta.env.MODE) {
    case 'test':
      return {
        apiKey: 'AIzaSyCIPsJ8yc5PUesCU-zafVsVt7_Y73bwIiA',
        authDomain: 'nitte-test.firebaseapp.com',
        databaseURL: 'https://nitte-test.firebaseio.com',
        projectId: 'nitte-test',
        storageBucket: 'nitte-test.appspot.com',
        messagingSenderId: '67151678395',
        appId: '1:67151678395:web:da8db3d452ab352ca5529e',
        measurementId: 'G-HKJJG145V9'
      }
    case 'staging':
      return {
        apiKey: 'AIzaSyDd0yRIXOBK6HXusiyaDUjNyAUoF6YXGUs',
        authDomain: 'nitte-staging.firebaseapp.com',
        databaseURL: 'https://nitte-staging.firebaseio.com',
        projectId: 'nitte-staging',
        storageBucket: 'nitte-staging.appspot.com',
        messagingSenderId: '758418394557',
        appId: '1:758418394557:web:41f2b251e8ad711843d6d4',
        measurementId: 'G-H9YN9T0GY2'
      }
    case 'production':
      return {
        apiKey: 'AIzaSyBRyO25JnHzY1YcSM0W5SSnatYzF7zx0vY',
        authDomain: 'nitte-90e8d.firebaseapp.com',
        databaseURL: 'https://nitte-90e8d.firebaseio.com',
        projectId: 'nitte-90e8d',
        storageBucket: 'nitte-90e8d.appspot.com',
        messagingSenderId: '1079413757044',
        appId: '1:1079413757044:web:0147fce68e9902d5e76d21',
        measurementId: 'G-P3LK0R3VBD'
      }
    default:
      return {
        apiKey: 'AIzaSyBtrpeeb6g4OdNMi1itL3o92_rr5A7wcxw',
        authDomain: 'nitte-dev.firebaseapp.com',
        databaseURL: 'https://nitte-dev.firebaseio.com',
        projectId: 'nitte-dev',
        storageBucket: 'nitte-dev.appspot.com',
        messagingSenderId: '310261265956',
        appId: '1:310261265956:web:df883c98abcce58e3b8ab1',
        measurementId: 'G-BT8XF4BXTD'
      }
  }
}
