<script setup lang="ts">
import { openExternalLink } from '@/common/helpers/openExternalLink';


const tweet = () => {
  openExternalLink(
    'http://twitter.com/share?url=https://text-gen.nitte.app?share=1&text=日時の文字を作りました⏰%0a&hashtags=日時の文字を作るくん',
    true
  )
}
</script>

<template>
  <button class="tweet-button lo-center pd-row bd-3px" @click="tweet">
    <img class="icon" src="@/assets/twitter.svg" />
    <label class="or-ft-white mg-left-8px">
      ツイート
      <span class="hide-narrow-device">する</span>
    </label>
  </button>
</template>

<style scoped>
.tweet-button {
  background-color: #38a8e0;
  height: 36px;
}
.tweet-button:hover {
  filter: contrast(150%);
}
.icon {
  height: 16px;
}
</style>