<script setup lang="ts">
import FBButton from '@/common/components/FBButton.vue';
import TweetButton from '@/common/components/TweetButton.vue';
import { useSpNotificationStore } from '@/SP/store/NotificationStore';
import { storeToRefs } from 'pinia';
const store = useSpNotificationStore()
const { bottomPosition } = storeToRefs(store)
</script>

<template>
  <div class="notification-wrapper" :style="{ bottom: bottomPosition.toPx() }">
    <strong-g class="copy-notification lo-v bg-white pd-rect shadow-mid">
      <div class="text-align-left or-ft-gray-1">
        <description>日時の文字をコピーしました。</description>
        <description>良ければシェアお願いします💡</description>
      </div>
      <!-- ツイートボタン -->
      <div class="lo-h fill-wt t-8px">
        <TweetButton class="fill-wt" />
        <FBButton class="mg-left-8px fill-wt" />
      </div>
    </strong-g>
  </div>
</template>

<style scoped>
.notification-wrapper {
  position: fixed;
  width: 100%;
  padding: 16px;
}
.copy-notification {
  border-radius: 12px;
}
</style>