<template>
  <div v-show="isInCurrentPage" class="current-time-indicator lo-h-center" :style="style">
    <div class="circle clip-circle" />
    <div class="fill-wt bar" />
  </div>
</template>

<script lang="ts">
import { useCalendarStore } from '@/PC/store/calendarStore'
import {
  Period,
  PeriodCoordinateCoder,
  toPx
} from '@WombatTechnology/nitte-time-lib'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CurrentTimeIndicator',
  computed: {
    ...mapState(useCalendarStore, ['coordinateConfig', 'currentPagePeriod']),
    style() {
      const currentTimePeriod = new Period(new Date(), new Date())
      const coordinate = PeriodCoordinateCoder.encode(
        currentTimePeriod,
        this.coordinateConfig
      )

      const { top, left, width } = toPx(coordinate)
      return {
        position: 'absolute',
        top,
        left,
        width
      }
    },
    isInCurrentPage(): boolean {
      const currentTimePeriod = new Period(new Date(), new Date())
      return (this.currentPagePeriod as Period).isSupersetOf(currentTimePeriod)
    }
  },
  methods: {}
})
</script>

<style scoped>
.current-time-indicator {
  /* 調整値 */
  margin-left: -4px;
  margin-top: -8px;
  z-index: var(--z-index-60);
}
.circle {
  clip-path: circle();
  width: 16px;
  height: 16px;
  background-color: #de3838;
}
.bar {
  border: 1px solid #de3838;
}
</style>
