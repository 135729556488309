<script setup lang="ts">
import FBButton from '@/common/components/FBButton.vue';
import TweetButton from '@/common/components/TweetButton.vue';
import { useNotificationStore } from '@/PC/store/notificationStore';
const store = useNotificationStore()
</script>

<template>
  <div class="notification-wrapper" :style="{ top: store.topPosition.toPx() }">
    <strong-g class="copy-notification lo-h-center bg-white pd-rect shadow-mid">
      <img src="@/assets/success.svg" />
      <div class="l-12px text-align-left">
        <description>日時の文字をコピーしました。</description>
        <description>シェアしていただけると開発の励みになります！</description>
      </div>
      <!-- ツイートボタン -->
      <div class="mg-left-32px">
        <TweetButton />
        <FBButton class="mg-left-8px" />
      </div>
    </strong-g>
  </div>
</template>

<style scoped>
.notification-wrapper {
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 120;
}
.copy-notification {
  border-radius: 12px;
}
</style>