import { sortBy } from "@WombatTechnology/nitte-time-lib";
import { getHours, getMinutes, set, startOfDay } from "date-fns";
import { isNull, isUndefined, uniqueId } from "lodash";
import { defineStore } from "pinia";
import { DateTime } from "./DateTime";
export type InputStatus = 'NoInput' | 'DateInputted' | 'StartInputted' | 'EndInputted'
export type State = {
  editingId: string | null,
  dateTimes: DateTime[]
}

export const useInputStore = defineStore('input', {
  state: (): State => {
    return {
      editingId: null,
      dateTimes: [],
    }
  },
  actions: {
    // 日の選択
    selectDate(date: Date) {
      console.log("select date", date)
      this.createDateTime({ date, startTime: null })
    },
    // 時間の選択
    selectTime(dateTime: Date) {
      console.log("select time", dateTime)
      // 初回いきなり時間を選択した場合
      if (isNull(this.currentEditingDateTime) || isUndefined(this.currentEditingDateTime)) {
        // ややこしいので一旦何もしない形
        return
      }

      if (isNull(this.currentEditingDateTime.startTime)) {
        // 開始時刻に代入
        this.currentEditingDateTime.startTime = dateTime
      } else if (isNull(this.currentEditingDateTime.endTime)) {
        // 終了時刻に代入
        this.currentEditingDateTime.endTime = dateTime
        this.editingId = null
      }
    },
    // DateTimeを新規作成する
    createDateTime({
      date,
      startTime
    }: {
      date: DateTime['date'],
      startTime: DateTime['startTime']
    }) {
      this.$patch((state) => {
        const id = uniqueId()
        state.dateTimes.push(new DateTime(
          id,
          date,
          startTime,
          null
        ))
        state.editingId = id
      })
    },
    reset() {
      this.dateTimes = []
    }
  },
  getters: {
    currentEditingDateTime(state): DateTime | null {
      const index = this.dateTimes.findIndex(d => d.id === this.editingId) ?? null
      if (isUndefined(index)) return null
      return state.dateTimes[index] as DateTime
    },
    isNoInput(): boolean {
      return isNull(this.currentEditingDateTime)
    },
    dateTimesSorted(): DateTime[] {
      const sortFunc = (dt: DateTime) => {
        if (isNull(dt.startTime)) {
          return startOfDay(dt.date ?? new Date())
        }
        const date = set(dt.date!, { hours: getHours(dt.startTime), minutes: getMinutes(dt.startTime) })
        return date
      }
      return sortBy<DateTime, Date>(this.dateTimes as DateTime[], sortFunc, 'asc')
    },
    dateTimesText(): string {
      return this.dateTimesSorted.map(dt => dt.format).join('\n')
    }
  }
})