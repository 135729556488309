<script setup lang="ts">
import { useUserStore } from "@/PC/store/userStore"
import { auth } from "@/common/helpers/auth"
import { storeToRefs } from "pinia"
import RegistrationModal from "./RegistrationModal.vue"

const userStore = useUserStore()
const { isLoggedIn, isCalendarScopeAuthorized } = storeToRefs(userStore)

</script>

<template>
  <div v-if="isLoggedIn" class="show-my-schedule">
    <div v-if="isCalendarScopeAuthorized">
      <a href="https://nitte.app/settings/account" target="_blank"
        >アカウント設定</a
      >で選択しているカレンダーの予定が表示されます。
    </div>
    <div v-else>
      ⚠️ カレンダーへのアクセス権限が許可されていないため、予定を表示できません。
      <a @click.prevent="auth">許可する</a>
    </div>
  </div>
  <registration-modal v-else>
    <div class="show-my-schedule">
      <a>自分の予定を表示する</a>
    </div>
  </registration-modal>
</template>

<style scoped>
.show-my-schedule {
  display: inline-flex;
}
</style>
