<template>
  <button
    class="button-container bd-8px lo-center nowrap"
    :disabled="isLoading || isDisabled || disabled"
    :class="{
      disabled: isDisabled,
      loading: isLoading,
      S: size === 'S',
      M: size === 'M',
      L: size === 'L',
    }"
    :style="{ ...buttonStyle, width, height }"
  >
    <div v-show="!isLoading" class="button-content">
      <slot></slot>
    </div>
  </button>
</template>

<script lang="ts">
import { NumberEnumProp, StringEnumProp } from '@/common/extensions/enum-props'
import { defineComponent, PropType } from 'vue'
import { ButtonState, ButtonType } from './types'

export default defineComponent({
  name: 'NitButton',
  props: {
    type: {
      ...StringEnumProp(ButtonType),
      default: 'main'
    },
    buttonState: {
      required: true,
      ...NumberEnumProp(ButtonState),
      default: ButtonState.Available
    },
    // 状態を持たないボタンで単純にdisabledしたい時はこっち使う
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String as PropType<'S' | 'M' | 'L'>,
      default: 'M'
    },
    width: {
      type: String,
      required: false
    },
    height: {
      type: String,
      required: false
    }
  },
  computed: {
    isLoading(): boolean {
      return this.$props.buttonState === ButtonState.Loading
    },
    isDisabled(): boolean {
      return this.$props.buttonState === ButtonState.Disabled
    },
    loadingRectSize(): number {
      return 46
    },
    buttonStyle() {
      // propsのtypeに合わせて動的にスタイル変数を生成して、styleから参照してる。
      return {
        '--color': `var(--${this.type})`,
        '--text-color': this.type === 'tertiary' ? '#291b0fcc' : 'white',
        '--hover-color': `var(--${this.type}-hover)`,
        '--active-color': `var(--${this.type}-active)`
      }
    }
  }
})
</script>

<style scoped>
.S {
  height: 32px;
  padding: 6px 16px;
  /* 14px-medium */
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-medium);
}
.M {
  width: 100%;
  max-width: 310px;
  height: 48px;
  padding: 12px 16px;
  font-weight: var(--font-weight-medium);
}
.L {
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  font-weight: var(--font-weight-medium);
}

/* デフォルトのスタイル */
.button-container {
  color: var(--text-color);
  background: var(--color);
  cursor: pointer;
  border-radius: var(--border-radius-3px);
}

/* リンクボタン */
.button-container >>> a {
  text-decoration: none;
  color: var(--text-color);
}

/* tertiaryだけスタイルが異なる */
.tertiary {
  border: 1px solid var(--font-gray-3);
}

/* Hover */
button:hover:enabled {
  background-color: var(--hover-color);
}
@media screen and (max-width: 800px) {
  /* スマホの場合タップするとhover状態になるため何もしない */
  button:hover:enabled {
    background: var(--color);
  }
}

/* Active */
button:active:enabled {
  background: var(--active-color);
}
button:focus-visible {
  background: var(--active-color);
}

/* Disabled */
.disabled {
  background: var(--disabled) !important;
}
button:disabled {
  cursor: not-allowed;
}

/* Loading */
.loading {
  /* Hoverより優先するため */
  background: var(--active-color) !important;
  /* Disabledより優先させるため */
  cursor: progress !important;
}

/* 中身のレイアウト */
.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* 中身をホバーしたときにポインターが出るようにするため */
.button-content >>> * {
  cursor: pointer;
}
</style>
