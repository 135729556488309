<template>
  <section class="strong-g">
    <slot />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GroupStrong'
})
</script>

<style scoped>
/* 強グループ同士のマージンは24pxデフォルト */
.strong-g:not(:first-element) {
  margin-top: var(--base-24px);
}

.strong-g.form:not(:first-element) {
  /* NOTE: フォームだけ協議の結果28pxを使うことに */
  margin-top: 28px;
}

.strong-g >>> .t-4px {
  margin-top: var(--base-4px);
}

.strong-g >>> .l-4px {
  margin-left: var(--base-4px);
}

.strong-g >>> .t-8px {
  margin-top: var(--base-8px);
}

.strong-g >>> .l-8px {
  margin-left: var(--base-8px);
}

.strong-g >>> .t-12px {
  margin-top: var(--base-12px);
}

.strong-g >>> .l-12px {
  margin-left: var(--base-12px);
}

.strong-g >>> .t-16px {
  margin-top: var(--base-16px);
}

.strong-g >>> .l-16px {
  margin-left: var(--base-16px);
}
</style>
