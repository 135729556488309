<script setup lang="ts">
import { useInputStore } from "@/SP/store/InputStore";
import MonthlyCalendar from './MonthlyCalendar.vue';
import TimeInput from "./TimeInput.vue";
const inputStore = useInputStore()
</script>

<template>
  <section class="date-time-input lo-v-center mg-top-16px">
    <MonthlyCalendar @select-date="inputStore.selectDate" />
    <TimeInput class="mg-top-8px" @select-time="inputStore.selectTime" />
  </section>
</template>

<style scoped>
/* css */
</style>