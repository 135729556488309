<script setup lang="ts">
import { useCalendarStore } from "@/PC/store/calendarStore"
import { formatHourMin, Period, PeriodCoordinateCoder, toPx } from "@WombatTechnology/nitte-time-lib";
import { addMinutes } from "date-fns";
import { storeToRefs } from "pinia"
import { computed } from "vue";

const calendarStore = useCalendarStore()

const {
  currentCusrorDate,
  coordinateConfig
} = storeToRefs(calendarStore)

const coordinate = computed(() => {
  const period = new Period(currentCusrorDate.value, addMinutes(currentCusrorDate.value, 30))
  return PeriodCoordinateCoder.encode(period, coordinateConfig.value)
})

</script>

<template>
  <div class="cursor-time position-absolute" :style="toPx(coordinate)">
    <roboto class="time-label gray-3">{{ formatHourMin(currentCusrorDate) }}〜</roboto>
  </div>
</template>

<style scoped>
.cursor-time {
  background-color: rgb(154, 154, 154, 0.2);
  pointer-events: none;
  z-index: 70;
}
.time-label {
  padding-left: var(--base-4px);
}
</style>