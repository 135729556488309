<template>
  <p class="or-ft-roboto" draggable="false" ondragstart="return false">
    <slot />
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Roboto'
})
</script>

<style scoped>
.roboto {
  font-family: "Roboto", sans-serif;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-16px);
}

/* Weight */
.bold {
  font-weight: var(--font-weight-bold);
}
.medium {
  font-weight: var(--font-weight-medium);
}
.regular {
  font-weight: var(--font-weight-regular);
}

/* Size */
.size-24px {
  font-size: var(--font-size-24px);
}
.size-18px {
  font-size: var(--font-size-18px);
}
.size-20px {
  font-size: 20px;
}
.size-16px {
  font-size: var(--font-size-16px);
}
.size-14px {
  font-size: var(--font-size-14px);
}
.size-12px {
  font-size: var(--font-size-12px);
}

/* Color */
.gray-1 {
  color: var(--font-gray-1);
}
.gray-2 {
  color: var(--font-gray-2);
}
.gray-3 {
  color: var(--font-gray-3);
}
</style>