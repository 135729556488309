export const NumberEnumProp = (e: { [s: number]: string | number }) => {
  return {
    type: Number,
    validator(value: number) {
      return Object.values(e).includes(value)
    }
  }
}

export const StringEnumProp = (e: { [s: number]: string | number }) => {
  return {
    type: String,
    validator(value: string) {
      return Object.values(e).includes(value)
    }
  }
}
