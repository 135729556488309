<script setup lang="ts">
import { usePeriodsStore } from "@/PC/store/periodsStore";
import {
  Coordinate,
  formatPeriodHourMin,
  PeriodWithId,
  toPx
} from "@WombatTechnology/nitte-time-lib";
import { toRefs } from "vue";

interface Props {
  period: PeriodWithId;
  isEditing: boolean;
  shown: boolean;
  coordinate: Coordinate;
}
const props = defineProps<Props>();
const { coordinate, isEditing } = toRefs(props);

const periodsStore = usePeriodsStore();

const emit = defineEmits<{
  (e: "mousemove", timelineX: number, timelineY: number): void;
}>();

const convertTimeLineMouseMove = (event: any) => {
  // layerX,YがこのSelectPerioの座標になってしまっているので
  // TimeLine上の座標になるように補正する
  const timelineX = event.layerX + coordinate.value.left;
  const timelineY = event.layerY + coordinate.value.top;
  emit("mousemove", timelineX, timelineY);
};

</script>

<template>
  <!-- 🖥 Desktop -->
  <div
    v-show="shown"
    draggable="false"
    ondragstart="return false"
    @mousedown.stop
    @mousemove.stop="convertTimeLineMouseMove"
    class="select-period or-ft-white bg-main position-absolute no-select"
    :style="{
      ...toPx(coordinate)
    }"
    :class="{ editing: isEditing }"
  >
    <button
      v-show="!isEditing"
      class="edit-start-invisible-button"
      @mousedown.stop="periodsStore.startEditing(period, 'start')"
    >editstart</button>
    <roboto class="time-label fill-wt no-select">
      {{
        formatPeriodHourMin(period)
      }}
    </roboto>
    <button
      v-show="!isEditing"
      class="edit-end-invisible-button"
      @mousedown.stop="periodsStore.startEditing(period, 'end')"
    >editend</button>
    <img
      @click.stop="periodsStore.remove(period)"
      class="close-button position-absolute"
      src="@/assets/close.svg"
    />
  </div>
</template>

<style scoped>
/* 調整値 */
.select-period {
  border-radius: 2px;
  cursor: pointer;
  z-index: 70;
}
.editing {
  box-shadow: 0px 4px 11px rgba(181, 181, 181, 0.51);
  cursor: ns-resize;
  background-color: rgb(154, 154, 154, 0.2);
}
.editing .or-ft-roboto {
  color: var(--font-gray-3);
}

.time-label {
  padding: var(--base-4px);
}
.close-button {
  opacity: 0;
  cursor: pointer;
  width: 30px;
  right: -7px;
  top: -10px;
}

.select-period:hover .close-button {
  opacity: 100;
}
.select-period.editing:hover .close-button {
  opacity: 0;
}
.edit-start-invisible-button {
  /* デバッグ用 */
  border: 10px solid red;
  opacity: 0;
  width: 100%;
  position: absolute;
  top: -5px;
  cursor: ns-resize;
}
.edit-end-invisible-button {
  border: 10px solid blue;
  opacity: 0;
  width: 100%;
  position: absolute;
  bottom: -5px;
  cursor: ns-resize;
}
</style>