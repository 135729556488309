<template>
  <div
    v-if="showOverlay"
    class="modal-background bg-modal fill-vw-vh overlay"
  ></div>
  <div
    v-if="$root.isMobile"
    class="modal-container lo-center overlay"
    @click.self="$emit('blur')"
  >
    <slot></slot>
  </div>
  <div
    v-else
    class="modal-container lo-center-h overlay scroll-auto"
    @click.self="$emit('blur')"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
// NOTE: カスタムコンポーネントではv-showが使えないようなのでv-if使うか<div>で囲む
// @see https://github.com/vuejs/vue/issues/1388
// deploy for hotfix
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NitModal',
  emits: ['blur'],
  props: {
    showOverlay: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style scoped>
.modal-background,
.modal-container {
  z-index: var(--z-index-100);
}
</style>
