<template>
  <section
    class="daily-time-line position-relative or-ft-gray-1"
    :style="{ height: dayLineHeight }"
  >
    <roboto class="size-14px timzone-label gray-1 text-align-right gray-2">{{ timeZoneLabel() }}</roboto>
    <div v-show="currentPeriodDayLineEvents.length > 0">
      <div v-for="allDayEvent in currentPeriodDayLineEvents" :key="allDayEvent.event.id">
        <all-day-event-period :all-day-event="allDayEvent" />
      </div>
    </div>
    <div class="vertical-lines lo-h-inline-flex-start">
      <div
        class="vertical-line fill-ht"
        :style="{ width: widthPerDay.toPx() }"
        v-for="idx in displayDateCountPerPage"
        :key="idx"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { timeZoneLabel } from '@/common/helpers/browserTimeZone'
import { useCalendarStore } from '@/PC/store/calendarStore'
import {
  AllDayEventDecorator
} from '@WombatTechnology/nitte-time-lib'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import AllDayEventPeriod from './AllDayEventPeriod.vue'


export default defineComponent({
  name: 'DayLine',
  components: {
    AllDayEventPeriod
  },
  data() {
    return {
      AllDayEventDecorator,
      timeZoneLabel
    }
  },
  props: {
    // NOTE: 全く意味ないyんやけどこれがないとmapStateのコンパイルが通らない
    foo: String
  },
  computed: {
    ...mapState(useCalendarStore, [
      'widthPerDay',
      'displayDateCountPerPage',
      'dayLineEvents',
      'showUserEvents',
      'dayEventHeight',
      'currentPagePeriod',
      'currentPeriodDayLineEvents'
    ]),
    dayLineHeight() {
      const rowCount =
        AllDayEventDecorator.maxRowIndex(this.currentPeriodDayLineEvents) + 1
      // rowCountがない or イベントを表示していない場合は場合はちょび髭の高さをとる
      if (rowCount === 0) {
        return '10px'
      }
      return (rowCount * this.dayEventHeight).toPx()
    }
  }
})
</script>

<style scoped>
.timzone-label {
  position: absolute;
  left: -50px;
  bottom: 4px;
}
.daily-time-line {
  margin-left: var(--hour-label-width);
}
.vertical-lines {
  height: var(--base-10px);
  border-bottom: 1px solid var(--border-gray);
  position: absolute;
  bottom: 0;
}
.vertical-line {
  border-left: 1px solid var(--border-black);
}
</style>
