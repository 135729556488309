<script setup lang="ts">
import NitButton from '@/common/components/NitButton/NitButton.vue';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useInputStore } from '../store/InputStore';
import { useSpNotificationStore } from '../store/NotificationStore';


const inputStore = useInputStore()
const { dateTimesText, dateTimes } = storeToRefs(inputStore)

const input = ref('')
watch(dateTimesText, (newVal) => {
  input.value = newVal
})

const copy = () => {
  navigator.clipboard.writeText(input.value).then(() => {
    const notificationStore = useSpNotificationStore()
    notificationStore.showNotification()
  })
}

const reset = () => {
  input.value = ''
  inputStore.reset()
}
</script>

<template>
  <section class="controller lo-v-center">
    <textarea
      v-model="input"
      class="textarea form-base"
      :rows="4"
      :placeholder="'カレンダーから選んだ日時が文字になって自動で入力されます。'"
    />
    <div class="buttons lo-v-center">
      <div class="lo-v-end">
        <a @click="reset" class="no-text-decoration lo-h-center">
          <img src="@/assets/reset.svg" alt="reset" />
          <label class="gray-1 mg-left-2px">リセット</label>
        </a>
        <nit-button
          class="mg-top-4px"
          @click="copy"
          :size="'L'"
          :width="'calc(100vw - 32px)'"
        >文字をコピー</nit-button>
      </div>
    </div>
  </section>
</template>

<style scoped>
.controller {
  background-color: var(--bg-form);
  padding-bottom: var(--base-16px);
  border-bottom: 1px solid var(--border-black);
}
.textarea {
  font-size: 20px;
  border: unset;
}
.buttons {
  z-index: 10;
  width: 100%;
}
</style>
