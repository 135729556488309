<script setup lang="ts">
import NitButton from '@/common/components/NitButton/NitButton.vue';
import { useNotificationStore } from '@/PC/store/notificationStore';
import { usePeriodsStore } from '@/PC/store/periodsStore';
import { formatDateWithDayName, formatPeriod } from '@WombatTechnology/nitte-time-lib';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useUserStore } from '../store/userStore';

const periodStore = usePeriodsStore()
const { periodsSorted } = storeToRefs(periodStore)
const periodsText = computed(() => {
  const periodFormatted = periodsSorted.value.map(p => {
    if (periodStore.isAllDay(p)) {
      return formatDateWithDayName(p.start)
    } else {
      return formatPeriod(p)
    }
  })
  return periodFormatted.join('\n')
})

const input = ref('')
watch(periodsText, (newVal) => {
  input.value = newVal
})

const copy = () => {
  navigator.clipboard.writeText(input.value).then(() => {
    useNotificationStore().showNotification()
  })
}

const reset = () => {
  input.value = ''
  periodStore.removeAll()
}

const userStore = useUserStore()
const { isLoggedIn } = storeToRefs(userStore)
const createPage = () => {
  const encodedPeriods = encodeURIComponent(JSON.stringify(periodsSorted.value))
  window.open(`${import.meta.env.VITE_APP_NITTE_HOST}/new?periods=${encodedPeriods}`, '_blank')
}
</script>

<template>
  <section class="controller lo-v fill-wt bg-white">
    <textarea
      class="form-base"
      v-model="input"
      :rows="8"
      :placeholder="'カレンダーから選んだ日時が文字になって自動で入力されます。'"
    />
    <strong-g class="mg-top-8px buttons lo-v-end">
      <nit-button class="mg-top-4px" @click="copy">文字をコピー</nit-button>
      <nit-button class="mg-top-8px border-gray" :type="'tertiary'" @click="reset">リセット</nit-button>
      <a v-if="isLoggedIn" @click="createPage" class="mg-top-4px lo-h-center ">
        <p>
          選んだ日時で調整する
        </p>
        <img :style="{ marginTop: '2px' }" src="@/assets/external-link-blue.svg" />
      </a>
    </strong-g>
  </section>
</template>

<style scoped>
.controller {
  padding: 96px 32px 0 32px;
  min-width: 320px;
}
.buttons {
  z-index: 10;
}
.reset-button {
  /* 調整値 */
  margin-right: 4px;
}
</style>
