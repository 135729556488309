<template>
  <section class="weak-g"><slot /></section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GroupStrong'
})
</script>

<style scoped>
.weak-g >>> .t-40px {
  margin-top: var(--base-40px);
}

.weak-g >>> .l-40px {
  margin-left: var(--base-40px);
}

.weak-g >>> .t-80px {
  margin-top: var(--base-80px);
}

.weak-g >>> .l-80px {
  margin-top: var(--base-80px);
}
</style>
