import { DocumentReference, getDoc } from "firebase/firestore"

/**
 * DocumentReferenceをデータに変換する
 * @param doc
 * @returns
 */
export const toData = async <M>(doc: DocumentReference<M>): Promise<M> => {
  const ds = await getDoc<M>(doc)
  return ds.data() as M
}
