<script setup lang="ts">
import { openExternalLink } from '@/common/helpers/openExternalLink';

const share = () => {
  openExternalLink(
    'https://www.facebook.com/sharer/sharer.php?u=https://text-gen.nitte.app',
    true
  )
}
</script>

<template>
  <button class="fb-button lo-center pd-row bd-3px" @click="share">
    <img class="icon" src="@/assets/fb.svg" />
    <label class="or-ft-white mg-left-8px">
      シェア
      <span class="hide-narrow-device">する</span>
    </label>
  </button>
</template>

<style scoped>
.fb-button {
  background-color: #2c73ff;
  height: 36px;
}
.fb-button:hover {
  filter: contrast(400%);
}
.icon {
  height: 18px;
}
</style>