<template>
  <h2 class="sub-title"><slot /></h2>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SubTitle'
})
</script>

<style scoped>
.sub-title {
  font-size: var(--font-size-18px);
  /* boldのみ */
  font-weight: var(--font-weight-medium);
}
</style>
