<script setup lang="ts">
import GoogleButton from "@/common/components/GoogleButton.vue"
import ModalWithButtons from "@/common/components/ModalWithButtons.vue"
import MsButton from "@/common/components/MsButton.vue"
import Description from "@/common/global-components/texts/Description.vue"
import { auth, authMs } from "@/common/helpers/auth"
import { ref } from "vue"

const show = ref(false)
</script>

<template>
  <div @click="show = true">
    <slot />
  </div>
  <div v-if="show">
    <modal-with-buttons @close="show = false">
      <template #title>自分の予定を表示する</template>
      <template #description>
        <strong-g class="lo-v-end">
          <Description>日程調整のNitteに登録すると自分の予定を見ながら日時を選択することができます。</Description>
          <a href="https://site.nitte.app" target="_blank">Nitteについて詳しくはこちら</a>
        </strong-g>
      </template>
      <template #contents>
        <section class="lo-v-center">
          <img class="with-my-schedule" src="@/assets/with-my-schedule.svg" />
          <google-button @click="auth" class="regist-button mg-top-24px">Googleで登録(無料)</google-button>
          <ms-button @click="authMs" class="regist-button mg-top-12px">
            Microsoftで登録(無料)
          </ms-button>
          <sup-description class="mg-top-8px">
            <span>登録することで、</span>
            <a href="https://site.nitte.app/terms">
              <span>利用規約</span>
            </a>
            <span>、</span>
            <a href="https://site.nitte.app/privacy">
              <span>プライバシーポリシー</span>
            </a>
            <span>に同意するものとします。</span>
            <span>
              セキュリティについて詳しくは
              <a href="https://site.nitte.app/security" target="_blank">こちら</a>
            </span>
          </sup-description>
          <description class="mg-top-24px">
            既にアカウントをお持ちの方
            <br />
            <a @click="auth">Googleでログイン</a>/<a @click="authMs">Microsoftでログイン</a>
          </description>
        </section>
      </template>
    </modal-with-buttons>
  </div>
</template>

<style scoped>
.with-my-schedule {
  width: 300px;
}

.regist-button {
  width: 250px;
}
</style>
