<script setup lang="ts">
import { Spacer } from '@/common/global-components';
import { useUserStore } from '@/PC/store/userStore';
import { storeToRefs } from 'pinia';
import UserMenu from './components/UserMenu.vue';

const userStore = useUserStore()
const { isLoggedIn } = storeToRefs(userStore)
</script>

<template>
  <navigation class="nav-header lo-h-center fill-wt bg-black or-ft-white pd-row">
    <img class="logo" src="@/assets/logo.svg" />
    <spacer />
    <user-menu v-if="isLoggedIn" />
  </navigation>
</template>

<style scoped>
.nav-header {
  height: 60px;
}
.logo {
  margin-left: 16px;
  height: 21px;
}
</style>