import gsap from "gsap";
import { defineStore } from "pinia";

export enum NotificationType {
  Success = 'success',
  Error = 'error'
}


// State
export type Notification = {
  topPosition: number,
}
export type State = Notification

const animationDuration = 0.3
const showPosition = 40
const hidePosition = -100
const durationForAutoClose = 7000

export const useNotificationStore = defineStore('notification', {
  state: (): State => {
    return {
      topPosition: hidePosition
    }
  },
  actions: {
    showNotification(
      autoClose: boolean = true
    ) {
      // 表示アニメーション
      gsap.to(this, {
        duration: animationDuration,
        topPosition: showPosition,
        ease: 'easeOut'
      })
      if (autoClose) {
        setTimeout(() => {
          this.hideNotification()
        }, durationForAutoClose)
      }
    },
    hideNotification() {
      gsap.to(this, {
        duration: animationDuration,
        topPosition: hidePosition,
        ease: 'easeOut'
      })
    }
  }
})