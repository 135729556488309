<script setup lang="ts">
import { useCalendarStore } from "@/PC/store/calendarStore";
import { range } from "lodash"
import { ref } from "vue";
const { hourHeight } = useCalendarStore()
const hours = ref(range(0, 24))
</script>

<template>
  <div class="hours-label-container lo-h-inline-flex-start">
    <div class="hours-label">
      <!-- Hour label -->
      <div
        class="hour-label"
        v-for="hour in hours"
        :style="{ height: hourHeight.toPx() }"
        :key="hour"
      >
        <roboto class="size-14px gray-1">{{ hour.toFullHour() }}:00</roboto>
      </div>
      <!-- Active Hours -->
      <!-- <roboto
        v-show="activeLabelTargetPeriod"
        class="current-select-period size-14px lo-v"
        :style="activeLabelStyle"
      >
        <roboto
          class="select-period-start or-ft-main"
        >{{ activeLabelTargetPeriod && activeLabel.start }}</roboto>
        <roboto
          class="select-period-end or-ft-main"
        >{{ activeLabelTargetPeriod && activeLabel.end }}</roboto>
      </roboto>-->
    </div>
    <!-- Horizontal Beard lines -->
    <div class="lo-v horizontal-lines">
      <div
        class="horizontal-line"
        v-for="idx in 24"
        :key="idx"
        :style="{ height: hourHeight.toPx(), width: '4px' }"
      />
    </div>
  </div>
</template>

<style scoped>
.hours-label-container {
  width: var(--hour-label-width);
}
.hours-label {
  /* 9pxは時間のラベルを線の中心にずらすための差分 */
  margin-top: -9px;
  position: relative;
  padding-left: var(--base-8px);
  /* 調整値 */
  padding-right: 15px;
}
.horizontal-line {
  border-color: var(--border-black) !important;
  opacity: 1;
  border-top: 1px solid;
  margin-left: -4px;
}
.current-select-period {
  position: absolute;
}
.current-select-period {
  justify-content: space-between;
}
/* Labelの調整マージン */
.select-period-start {
  margin-top: -9px;
}
.select-period-end {
  margin-bottom: -7px;
}
</style>
