import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import envFirebaseConfig from './envFirebaseConfig'
import { isNotNil } from './lodash-ext'

// Initialize Firebase
const firebaseConfig = envFirebaseConfig()
initializeApp(firebaseConfig)
console.log('Start Firebase 🔥', import.meta.env.MODE)

// Auth
export const auth = getAuth()
export const uid = () => auth.currentUser?.uid || null
export const email = () => auth.currentUser?.email || null
export const isLoggedIn = () => isNotNil(uid())

export const store = getFirestore()
export const storage = getStorage()

if (import.meta.env.VITE_APP_USE_EMULATOR) {
  console.log('🔥Firebase use firestore emulator')
  console.log('Functions host', import.meta.env.VITE_APP_CLOUD_FUNCTION_URL)
  console.log(
    'Firestore host',
    import.meta.env.VITE_APP_FIRE_STORE_EMULATOR_HOST
  )
  connectFirestoreEmulator(store, 'localhost', 5002)
  connectAuthEmulator(auth, 'http://localhost:5004')
}
