<template>
  <section class="medium-g"><slot /></section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'GroupStrong'
})
</script>

<style scoped>
/* 中グループ同士のマージンは40pxデフォルト */
.medium-g:not(:first-child) {
  margin-top: var(--base-40px);
}

.medium-g >>> .t-24px {
  margin-top: var(--base-24px);
}
.medium-g >>> .l-24px {
  margin-left: var(--base-24px);
}
</style>
