<template>
  <div class="event-container pointer-events-none noselect" :style="coordinate">
    <!-- 🖥 Desktop -->
    <div class="fill-wt-ht">
      <div
        class="event-period-base fill-wt-ht"
        :class="{
          'overlap-border': timeLineEvent.indent > 0
        }"
        @click="onClickEvent"
      >
        <small-text data-private class="event-label regular">{{ timeLineEvent.event.title }}</small-text>
        <roboto class="event-label size-12px">{{ formatPeriodHourMin(timeLineEvent.event.period) }}</roboto>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { openExternalLink } from '@/common/helpers/openExternalLink'
import { useCalendarStore } from '@/PC/store/calendarStore'
import {
  formatPeriodHourMin, NitteCalendarEvent,
  NitteCalendarTimeLineEvent, TimeLineEventCoordinateCoder,
  toPx
} from '@WombatTechnology/nitte-time-lib'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'EventPeriod',
  data() {
    return {
      formatPeriodHourMin,
      openExternalLink,
      selectingInSp: false
    }
  },
  props: {
    timeLineEvent: {
      required: true,
      type: Object as PropType<NitteCalendarTimeLineEvent>
    },
    startDate: {
      required: true,
      type: Date
    }
  },
  computed: {
    ...mapState(useCalendarStore, ['coordinateConfig']),
    event(): NitteCalendarEvent | null {
      return this.timeLineEvent?.event
    },
    coordinate() {
      const coordinate = TimeLineEventCoordinateCoder.encode(
        this.timeLineEvent,
        this.coordinateConfig
      )
      return {
        ...toPx(coordinate),
        'z-index': 60 + this.timeLineEvent.indent
      }
    }
  },
  methods: {
    onClickEvent() {
      openExternalLink(this.$props.timeLineEvent.event.htmlLink ?? '', true)
    }
  }
})
</script>

<style scoped>
.overlap-border {
  border: 1px solid #c6c6c6;
  /* #484848 */
}
.event-container {
  position: absolute;
}
.event-tool-tip /deep/ .message {
  padding: 4px;
  display: flex;
}
.event-tool-tip /deep/ .triangle {
  margin-left: 15px;
}
.external-link {
  height: 16px;
  width: 16px;
}
.sp-selected {
  background-color: var(--accent);
}
</style>
