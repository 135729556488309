import {
  NitteCalendarEvent, Period, Periods
} from '@WombatTechnology/nitte-time-lib'
import { parseISO } from 'date-fns'
import { Dictionary, flatten } from 'lodash'
export interface PeriodResponse {
  start: string
  end: string
}

export interface PeriodsResponse {
  periods: PeriodResponse[]
}

export type EventResponse = Omit<NitteCalendarEvent, 'period'> & {
  period: PeriodResponse
}

const parsePeriodResponse = (p: PeriodResponse): Period | Period[] => {
  // NOTE: ここで勝手にUTCをブラウザの時間に変更してくれてるので一旦これで。
  // タイムゾーンに対応する場合ここの対応が必要になる。
  const start = parseISO(p.start)
  const end = parseISO(p.end)

  return new Period(start, end)
}

export const parsePeriodsResponse = (response: PeriodsResponse): Periods => {
  const periodArray: Period[] = flatten(
    response.periods.map((p) => parsePeriodResponse(p))
  )

  return new Periods(periodArray)
}

export const parseDictionaryPeriodsResponse = (
  response: Dictionary<{ periods: PeriodResponse[] }>
): Dictionary<Periods> => {
  const dictionaryPeriods: Dictionary<Periods> = {}

  for (const [userId, periodResponse] of Object.entries(response)) {
    dictionaryPeriods[userId] = parsePeriodsResponse(periodResponse)
  }

  return dictionaryPeriods
}

export const parseEventResponse = (
  event: EventResponse
): NitteCalendarEvent => {
  const { id, title, isAllDay, period, htmlLink, onlineMeetingURL, hasSchedule } = event
  return {
    id,
    title,
    period: parsePeriodResponse(period) as Period,
    isAllDay,
    onlineMeetingURL,
    htmlLink,
    hasSchedule
  }
}
