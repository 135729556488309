export enum ButtonState {
  Available,
  Loading,
  Disabled
}
export enum ButtonType {
  Main = 'main',
  Accent = 'accent',
  Error = 'error',
  Tertiary = 'tertiary',
  Google = 'google-blue',
  MS = 'ms',
  Twitter = 'twitter',
  FB = 'fb',
  Attention = 'attention'
}
